import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/main.css";
import "../css/mainTwo.css";
import { Link } from "react-router-dom";

function Main() {
  return (
    <div className="main-container">
      <Header />
      <div className="main1_box">
        <div className="main1_box_left">
          <img
            alt=""
            src="https://www.singaporetech.edu.sg/sites/default/files/2024-10/Tongxuan%20with%20mother%20hero%20resized.jpg"
          />
          <div className="main1_section-header">
            <div className="main1_section-header__header">
              <h3>Class of 2024: Stepping Up Against All Odds</h3>
            </div>
            <div className="main1_section-header__subtitle">
              <p className="main1_p">
                When the going gets tough, the tough get going. Two SITizens
                from the Class of 2024 pressed on despite health issues and
                personal grief to get to the finish line.
              </p>
              <div className="main1_accent--md"></div>
            </div>
          </div>
        </div>
        <div className="main1_box_right">
          <div className="main1_first">
            <img
              loading="lazy"
              src="https://www.singaporetech.edu.sg/sites/default/files/2024-10/Slide1.jpg"
              alt="Students"
              typeof="foaf:Image"
            />
            <div className="main1_section-header">
              <div className="main1_section-header__header">
                <h5>Class of 2024: Helping Patients Find Their Voice</h5>
              </div>
            </div>
          </div>
          <div className="main1_first">
            <img
              loading="lazy"
              src="https://www.singaporetech.edu.sg/sites/default/files/2024-10/New%20Project%20%2813%29.jpg"
              alt="Students"
              typeof="foaf:Image"
            />
            <div className="main1_section-header">
              <div className="main1_section-header__header">
                <h5>
                  Class of 2024: Shaping New Chapters Through Lifelong Learning
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main2_box">
        <div className="main2_box_inner">
          <div className="main2_title">Highlights</div>
          <div className="main2_row-2">
            <div className="main2_d-flex ">
              <ul>
                <li>Features</li>
                <li>Press Releases</li>
                <li>in the news</li>
              </ul>
              <Link
                className="lead-link text-uppercase ml-auto d-none d-lg-inline"
                to="/"
              >
                <span className="lead-link__text">see all news</span>
              </Link>
            </div>
          </div>
          <div className="main2_tab">
            <div className="main2_tab_inner">
              <div className="main2_tab_inner_left">
                <img
                  alt=""
                  src="https://www.singaporetech.edu.sg/sites/default/files/styles/card_rectangle_medium/public/2024-10/resized%20image%20human%20capital%20conference.jpg?itok=VlSbcbBO"
                />
                <div className="main2_tab_body">
                  <ul>
                    <li className="title-2">
                      Why SMEs Should Tap on IHLs for Upskilling Needs{" "}
                    </li>
                    <li className="date">14 Oct 2024</li>
                    <li className="excerpt">
                      Institutes of Higher Learning (IHLs) offer a wealth of
                      resources, such as Applied Research Centres (ARCs) and
                      customised learning pathways for working adults.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="main2_tab_inner_mid"></div>
              <div className="main2_tab_inner_right">
                <div className="main2_tab_inner_right_new">
                  <img
                    alt=""
                    src="https://www.singaporetech.edu.sg/sites/default/files/styles/card_rectangle_medium/public/2024-09/CGF_Hero.jpg?itok=uxrZWAvX"
                  />
                  <div className="main2_tab_inner_right_new_body">
                    <ul>
                      <li className="title-2">
                        Building a Sustainable Future One Beam at a Time
                      </li>
                      <li className="date">9 Oct 2024</li>
                    </ul>
                  </div>
                </div>
                <div className="main2_tab_inner_right_new">
                  <img
                    alt=""
                    src="https://www.singaporetech.edu.sg/sites/default/files/styles/card_rectangle_medium/public/2024-09/EEE%20CSM%20Class_hero.jpg?itok=Gm96VEyz"
                  />
                  <div className="main2_tab_inner_right_new_body">
                    <ul>
                      <li className="title-2">
                        SIT Launches First Stackable Competency-based
                        Engineering Degrees in Singapore
                      </li>
                      <li className="date">4 Oct 2024</li>
                    </ul>
                  </div>
                </div>
                <div className="main2_tab_inner_right_new">
                  <img
                    alt=""
                    src="https://www.singaporetech.edu.sg/sites/default/files/styles/card_rectangle_medium/public/2024-09/ClubFair%202024%20%2824%29%20%281%29_0.jpg?itok=NyJoF4cb"
                  />
                  <div className="main2_tab_inner_right_new_body">
                    <ul>
                      <li className="title-2">
                        A Mascot That’s OTTER-ly Right for SIT
                      </li>
                      <li className="date">1 Oct 2024</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="main3_box">
        <div className="main3_box_inner">
          <div className="main3_box_inner_flex">
            <div className="mian3_flex_div">
              <div className="heading">
                Future disruptive changes call for agile thinkers who are
                masters at their craft.
              </div>
            </div>
            <div className="mian3_flex_div">
              <div className="content">
                <div className="heading-2">Beyond The Classroom</div>
                <div className="desc">
                  Knowledge is attained from learning. Wisdom is gained from
                  experience. At SIT, you get both. You learn about
                  technologies, algorithms, and the laws that govern the
                  universe. And you get to apply what you have learnt to build
                  machines, systems, and tools that will solve tomorrow’s
                  problems.
                  <br />
                  <br /> Our lecturers are a mix of academics, professionals,
                  and innovators who bring real-world experiences and the latest
                  discoveries in their fields to our programmes. Whether in the
                  laboratory or the classroom, students deal with cutting-edge
                  technology on a daily basis. Learning by thinking and doing,
                  continuously creating and disrupting.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="main4_box">
        <div className="main4_box_inner">
          <div className="main4_flex_inner">
            <img
              alt=""
              src="	https://www.singaporetech.edu.sg/sites/default/files/homepage/bg-cluster-carousel-design.jpg"
            />
            <ul>
              <li className="title">Business, Communication and Design</li>
              <li className="desc">
                Here’s where science meets creativity and commerce. Choose from
                degrees across a variety of client-facing industries, such as
                digital communication, integrated media, hospitality business,
                accountancy, and the culinary arts.
              </li>
            </ul>
          </div>
          <div className="main4_flex_inner">
            <img
              alt=""
              src="https://www.singaporetech.edu.sg/sites/default/files/homepage/bg-cluster-carousel-engineering.jpg"
            />
            <ul>
              <li className="title">Engineering</li>
              <li className="desc">
                What will you build for tomorrow’s world? Explore degree
                programmes in aerospace, civil, electrical, mechanical, marine,
                and data engineering.
              </li>
            </ul>
          </div>
          <div className="main4_flex_inner">
            <img
              alt=""
              src="https://www.singaporetech.edu.sg/sites/default/files/homepage/bg-cluster-carousel-chem.jpg"
            />
            <ul>
              <li className="title">Food, Chemical and Biotechnology</li>
              <li className="desc">
                Understand how raw materials are turned into plastics, metals,
                clothing, and energy. Study the technology behind medicines that
                heal and foods that nourish. Discover ways to enhance life,
                promote wellness, protect the environment, and grow economies.
              </li>
            </ul>
          </div>
          <div className="main4_flex_inner">
            <img
              alt=""
              src="https://www.singaporetech.edu.sg/sites/default/files/homepage/bg-cluster-carousel-health.jpg"
            />
            <ul>
              <li className="title">Health and Social Sciences</li>
              <li className="desc">
                Science continues to enhance healthcare and revolutionise a
                range of therapies. Learn the technologies and techniques behind
                nursing and allied health services like radiography,
                physiotherapy, occupational therapy, speech therapy, dietetics
                and nutrition.
              </li>
            </ul>
          </div>
          <div className="main4_flex_inner">
            <img
              alt=""
              src="https://www.singaporetech.edu.sg/sites/default/files/homepage/bg-cluster-carousel-info.jpg"
            />
            <ul>
              <li className="title">Infocomm Technology</li>
              <li className="desc">
                Build systems that keep the world connected. Become a
                professional in information and communication technologies with
                degree programmes like computer science, infocomm security,
                telematics, game design, and digital art.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="main5_box">
        <div className="main5_box_inner">
          <div className="main5_box_inner_flex">
            <div className="main5_flex_inner_left">
              <img
                width="600"
                height="100%"
                alt=""
                src="	https://www.singaporetech.edu.sg/sites/default/files/2020-11/Why%20SIT_Charlene.jpg"
              />
            </div>
            <div className="main5_flex_inner_mid"></div>
            <div className="main5_flex_inner_right">
              <div className="heading ">about us</div>
              <div className="heading-2">Why SIT?</div>
              <div className="desc">
                Singapore Institute of Technology (SIT) is Singapore’s
                University of Applied Learning, offering applied degree
                programmes targeted at growth sectors of the economy. With a
                mission to nurture and develop individuals who impact society in
                meaningful ways, SIT aims to be a leader in innovative learning
                by integrating learning, industry, and community.
                <br />
                <br />
                The university’s unique pedagogy integrates work and study by
                incorporating applied research for students to work on real
                industry problems and creating solutions that meet industry
                needs. SIT also advocates the work-learn continuum, which places
                an emphasis on upskilling and lifelong learning. <br />
                <br />
                Spread across six campuses, and partnered with nine reputable
                overseas universities, SIT offers a curriculum that blends
                innovation and expertise with applied research and practical
                experience.
                <br />
                <br />
                <Link to="/about">
                  <span className="block-link__text">Explore More</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    className="icon-icon--sm"
                  >
                    <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="main6_box">
        <div className="main6_box_inner">
          <div className="main6_box_inner_flex">
            <div className="main5_flex_inner_right">
              <div className="heading ">applied learning</div>
              <div className="heading-2">
                Singapore's University of Applied Learning
              </div>
              <div className="desc">
                SIT has a unique pedagogy that integrates work and study. Our
                curriculum combines knowledge and research with hands-on
                practical skills. You’ll get to experience theories in action by
                working up close with machinery and technology.
                <br />
                <br />
                Each programme includes our signature Integrated Work Study
                Programme (IWSP) which immerses students in real work
                environments, allowing them to tackle real-world problems and
                understand what industries and markets require. Students will
                gain insights that could fuel ideas for future innovations.
                <br />
                <br />
              </div>
            </div>
            <div className="main6_flex_mid"></div>
            <div className="main6_flex_right">
              <img
                width="600"
                height="704"
                alt=""
                src="https://www.singaporetech.edu.sg/sites/default/files/homepage/applied-learning.jpg"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="main7_box">
        <div className="main7_box_inner">
          <div className="main7_box_title">
            <div className="title">Life on Campus</div>
            <Link to="/">
              <span className="lead-link__text">explore life @ sit</span>
            </Link>
          </div>

          <div className="main7_box_body">
            <div className="main7_box_body_inner">
              <div className="main7_body_inner">
                <div className="main7_img1"></div>
                <ul>
                  <li className="title-2">First Year Experience</li>
                </ul>
              </div>
            </div>
            <div className="main7_box_body_inner">
              <div className="main7_body_inner">
                <div className="main7_img2"></div>
                <ul>
                  <li className="title-2">Find your Tribe</li>
                </ul>
              </div>
            </div>
            <div className="main7_box_body_inner">
              <div className="main7_body_inner">
                <div className="main7_img3"></div>
                <ul>
                  <li className="title-2">Life Readiness</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="main8_box">
        <div className="main8_box_inner">
          <div className="main8_box_title">
            Hear from
            <br />
            our SITizens
          </div>
          <div className="main8_box_body">
            <div className="main8_box_body_inner">
              <div className="main8_body_left"></div>
              <div className="main8_body_mid">
                <div className="main8_body_mid_img">
                  <img
                    width="100%"
                    height="100%"
                    alt=""
                    src="	https://www.singaporetech.edu.sg/sites/default/files/2023-04/Hanna-Amani-400x400_0.jpg"
                  />
                </div>
              </div>
              <div className="main8_body_right">
                <ul>
                  <li className="who text-uppercase">
                    Hanna Amani Binte Mahmud <br /> Software Engineer,
                    Continental AG
                  </li>
                  <li className="quote">
                    SIT is a microcosm of the working world, where we work in
                    projects with varying dynamics. We learn soft skills to
                    communicate our ideas effectively, which goes a long way in
                    resolving conflicts.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="main9_box">
        <div className="main9_box_inner">
          <div className="main9_box_inner_flex">
            <div className="main9_box_left">
              <div class="title">Instagram</div>
              <div class="title-2">
                Get
                <br />
                Social
              </div>
              <div class="desc">
                Life in SIT is not just about lectures and labs. See what our
                students have been up to.
              </div>
            </div>
            <div className="main9_box_right">
              <img
                alt=""
                src="https://curator-assets.b-cdn.net/b338f672-f936-452a-99d9-1680370d2146/359605525.jpg?width=480&quality=75"
              />
              <img
                alt=""
                src="https://curator-assets.b-cdn.net/b338f672-f936-452a-99d9-1680370d2146/359441649.jpg?width=480&quality=75"
              />
              <img
                alt=""
                src="https://curator-assets.b-cdn.net/b338f672-f936-452a-99d9-1680370d2146/359390724.jpg?width=480&quality=75"
              />
              <img
                alt=""
                src="https://curator-assets.b-cdn.net/b338f672-f936-452a-99d9-1680370d2146/359217493.jpg?width=480&quality=75"
              />
              <img
                alt=""
                src="https://curator-assets.b-cdn.net/b338f672-f936-452a-99d9-1680370d2146/359212454.jpg?width=480&quality=75"
              />
              <img
                alt=""
                src="https://curator-assets.b-cdn.net/b338f672-f936-452a-99d9-1680370d2146/359212006.jpg?width=480&quality=75"
              />
              <img
                alt=""
                src="https://curator-assets.b-cdn.net/b338f672-f936-452a-99d9-1680370d2146/359074109.jpg?width=480&quality=75"
              />
              <img
                alt=""
                src="https://curator-assets.b-cdn.net/b338f672-f936-452a-99d9-1680370d2146/359066814.jpg?width=480&quality=75"
              />
              <img
                alt=""
                src="https://curator-assets.b-cdn.net/b338f672-f936-452a-99d9-1680370d2146/359060140.jpg?width=480&quality=75"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Main;
