import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import "../css/about.css";
import { Link } from "react-router-dom";

function About() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="main-container">
      <Header />
      <div className="privacy_box">
        <div className="privacy_h1">
          <ol className="breadcrumbs__list">
            <li className="breadcrumbs__li">
              <Link className="nav-link" to="/" onClick={toTop}>
                Home
              </Link>
            </li>
            <li className="breadcrumbs__li">
              <Link className="nav-link nav-link--active" to="">
                About SIT
              </Link>
            </li>
          </ol>
        </div>
        <div className="about_body">
          <div className="about_body_img">
            <h1>About</h1>
          </div>
          <div className="about_body_txt">
            <p>
              The Singapore Institute of Technology (新加坡理工大学) or SIT
              (新工大) is Singapore’s first University of Applied Learning,
              offering specialised degree programmes that prepare its graduates
              to be work-ready professionals. With a{" "}
              <a href="/about">mission </a>to develop individuals and innovate
              with industry to impact the economy and society in meaningful
              ways, SIT aims to also be a leader in innovative workplace
              learning and applied research.
            </p>
            <p>
              The University’s unique pedagogy integrates work and study,
              embracing authentic learning in a real-world environment through
              collaborations with key strategic partners. Its focus on applied
              research with business impact is aimed at helping industry
              innovate and grow. The University’s centralised campus in Punggol
              features a fit-for-purpose campus within the larger Punggol
              Digital District, where academia and industry are tightly
              integrated with the community.
            </p>
            <h2>Discover More About SIT</h2>
            <div className="about_discover">
              <div className="about_discover_inner">
                <div className="discover_img">
                  <img
                    alt=""
                    src="https://www.singaporetech.edu.sg/sites/default/files/styles/card_rectangle_small/public/2020-11/who-we-are.jpg?itok=zxD2BWOm"
                  />
                </div>
                <div className="discover_txt">
                  <h1>Our History</h1>
                  <div class="page-card__body">
                    <div class="accent accent--tall"></div>
                    <div class="field--name-field-card-page-summary ">
                      Singapore Institute of Technology was started in 2009 to
                      provide more opportunities for polytechnic graduates to
                      further their education.
                    </div>
                  </div>
                </div>
              </div>
              <div className="about_discover_inner">
                <div className="discover_img">
                  <img
                    alt=""
                    src="https://www.singaporetech.edu.sg/sites/default/files/styles/card_rectangle_small/public/2020-10/Leadership_2.jpg?itok=avc0hb6e"
                  />
                </div>
                <div className="discover_txt">
                  <h1>Leadership</h1>
                  <div class="page-card__body">
                    <div class="accent accent--tall"></div>
                    <div class="field--name-field-card-page-summary ">
                      The SIT Board of Trustees governs and sets the strategic
                      direction for the University, which is led by its
                      President and Senior Management.
                    </div>
                  </div>
                </div>
              </div>
              <div className="about_discover_inner">
                <div className="discover_img">
                  <img
                    alt=""
                    src="https://www.singaporetech.edu.sg/sites/default/files/styles/card_rectangle_small/public/2020-10/Who-We-are.jpg?itok=0uEephRh"
                  />
                </div>
                <div className="discover_txt">
                  <h1>SITizen-DNA</h1>
                  <div class="page-card__body">
                    <div class="accent accent--tall"></div>
                    <div class="field--name-field-card-page-summary ">
                      The SITizen-DNA serves as necessary guiding principles
                      that steer our curriculum design, pedagogical approach and
                      student interaction. These, in turn, nurture students to
                      embody the SITizen-DNA.
                    </div>
                    <p></p>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="about_gray">
            <div className="about_gray_inner">
              <div className="about_gray_body">
                <img
                  alt=""
                  src="https://www.singaporetech.edu.sg/sites/default/files/2020-10/square.jpg"
                />
                <div className="about_gray_body_txt">
                  <h2>Applied Research Office</h2>
                  <p>
                    The ARO provides the strategic direction and governance for
                    applied research in the university. We conduct research that
                    is relevant and useful to the growth industries. Our
                    research interest areas include energy, food, cybersecurity,
                    maintenance engineering, and community healthcare
                    transformation.
                  </p>
                </div>
              </div>
              <div className="about_gray_body">
                <img
                  alt=""
                  src="https://www.singaporetech.edu.sg/sites/default/files/2020-10/SITLearn.jpg"
                />
                <div className="about_gray_body_txt">
                  <h2>Continuing Education Centre</h2>
                  <p>
                    <strong>
                      SIT<em>LEARN</em>
                    </strong>
                    &nbsp; is the lifelong learning division of the university.
                    Working adults can gain and develop new skills for practical
                    applications in their workplace. The short courses serve a
                    wide range of industries, from Building Services,
                    Cybersecurity, Data Analytics, Engineering, Health Sciences,
                    Hospitality, and Process Safety.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="about_four">
            <div className="about_four_inner">
              <div className="about_four__body">
                <div className="four_img-1 four_imgs">
                  <h4>Centre for Career Readiness</h4>
                </div>
                <ul>
                  <li class="title-2">
                    <div class="field field--name-field-title field--type-string field--label-hidden field__item">
                      Centre for Career Readiness
                    </div>
                  </li>
                  <li class="desc">
                    <div class="field field--name-field-card-life-summary field--type-string-long field--label-hidden field__item">
                      The Centre for Career Readiness helps students develop
                      essential career skills and fully prepare them to become a
                      working professional.
                    </div>
                  </li>
                </ul>
              </div>
              <div className="about_four__body">
                <div className="four_img-2 four_imgs">
                  <h4>Centre for Communication Skills</h4>
                </div>
                <ul>
                  <li class="title-2">
                    <div class="field field--name-field-title field--type-string field--label-hidden field__item">
                      Centre for Communication Skills
                    </div>
                  </li>
                  <li class="desc">
                    <div class="field field--name-field-card-life-summary field--type-string-long field--label-hidden field__item">
                      The Centre for Communication Skills helps students improve
                      their English language proficiency and communication
                      skills through conducting communication modules for SIT
                      degree programmes.
                    </div>
                  </li>
                </ul>
              </div>
              <div className="about_four__body">
                <div className="four_img-3 four_imgs">
                  <h4>Centre for Digital Enablement</h4>
                </div>
                <ul>
                  <li class="title-2">
                    <div class="field field--name-field-title field--type-string field--label-hidden field__item">
                      Centre for Digital Enablement
                    </div>
                  </li>
                  <li class="desc">
                    <div class="field field--name-field-card-life-summary field--type-string-long field--label-hidden field__item">
                      The Centre for Digital Enablement drives and supports
                      integrative digital frameworks and platforms within the
                      Singapore Institute of Technology.
                    </div>
                  </li>
                </ul>
              </div>
              <div className="about_four__body">
                <div className="four_img-4 four_imgs">
                  <h4>
                    Centre for Learning Environment and Assessment Development
                  </h4>
                </div>
                <ul>
                  <li class="title-2">
                    <div class="field field--name-field-title field--type-string field--label-hidden field__item">
                      Centre for Learning Environment and Assessment Development
                    </div>
                  </li>
                  <li class="desc">
                    <div class="field field--name-field-card-life-summary field--type-string-long field--label-hidden field__item">
                      The Centre for Learning Environment and Assessment
                      Development promotes collaboration and applied learning
                      through the development of effective teaching and
                      assessment practice amongst faculty.
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="about_annual">
            <div className="about_annual_inner">
              <div className="about_annual_inner_left">
                <h1>Annual Report 2024</h1>
                <p>
                  This year’s Annual Report celebrates a significant milestone
                  for the Singapore Institute of Technology (SIT) – a decade of
                  forging our path as an Autonomous University. This anniversary
                  not just commemorates the years that have passed, but is a
                  testament to the impactful journey we’ve undertaken.
                </p>
              </div>
              <div className="about_annual_inner_right">
                <img
                  alt=""
                  src="	https://www.singaporetech.edu.sg/sites/default/files/2024-09/annual-report-2024-big.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
