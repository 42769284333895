import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import "../css/privacy.css";
import { Link } from "react-router-dom";

function Privacy() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="main-container">
      <Header />
      <div className="privacy_box">
        <div className="privacy_h1">
          <ol className="breadcrumbs__list">
            <li className="breadcrumbs__li">
              <Link className="nav-link" to="/" onClick={toTop}>
                Home
              </Link>
            </li>
            <li className="breadcrumbs__li">
              <Link className="nav-link nav-link--active" to="">
                Privacy Statement
              </Link>
            </li>
          </ol>
        </div>
        <div className="privacy_body">
          <h1>Privacy Statement</h1>
          <p>
            This is a Singapore Institute of Technology (SIT) website. Thank you
            for examining our privacy statement.
          </p>
          <ol>
            <li>
              If you are only browsing this website or using the Search
              function, we do not capture data that allows us to identify you
              individually. This site automatically receives and records
              information on our server logs from your browser, including your
              IP address, cookie information, and the page(s) requested.
              Although user sessions are tracked, the users remain anonymous.
              <br />
              &nbsp;
            </li>
            <li>
              If you choose to send us an application or an e-mail that contains
              personally identifiable data, for us to process the application or
              to render you a service, we may share relevant data within SIT, or
              if necessary, with other Government agencies or universities, so
              as to serve you efficiently and effectively, unless such sharing
              is prohibited by legislation. We may also need to disclose
              personal data as required by law or a court order. We will NOT
              share your personal data with private organisations, except where
              such entities have been authorized to carry out specific SIT
              services. Should the personal data supplied to SIT be out-of-date,
              please supply us with the latest data. We will retain your
              personal data only as necessary for the effective delivery of our
              services to you. For further details on SIT’s management of your
              personal data, please view SIT’s
              <Link to="/media/personal-data-protection-and-privacy-policy">
                Personal Data Protection and Privacy Policy
              </Link>
              .<br />
              &nbsp;
            </li>
            <li>
              <div>
                This site may contain links to other independently-managed
                websites within the SIT network and to some sites outside the
                “SingaporeTech.edu.sg” domain whose data protection and privacy
                practices may be different from ours. We are not responsible for
                the content and privacy practices of these other websites and
                encourage you to examine the privacy notices of those sites.
                <br />
                &nbsp;
              </div>
            </li>
            <li>
              If you have queries relating to how SIT deals with personal data
              under the Personal Data Protection Act, please contact our Data
              Protection Officer at&nbsp;
              <Link to="mailto:utubu@hoihat.buzz">utubu@hoihat.buzz</Link>
              or such other person as SIT may designate from time to time.
            </li>
          </ol>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Privacy;
