import React, { useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import "../css/contact.css";
import { Link } from "react-router-dom";

function Contact() {
  const [contactNumber, setContactNumber] = useState("");
  const [designation, setDesignation] = useState("");
  const [company, setCompany] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
  });
  const [errors, setErrors] = useState({
    name: false,
    email: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: false,
      }));
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const newErrors = {
      name: formData.name.trim() === "",
      email: formData.email.trim() === "",
    };
    setErrors(newErrors);

    const hasErrors = Object.values(newErrors).some((error) => error);
    if (hasErrors) {
      alert("Please fill in all required information!");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return;
    }

    alert("Submit Success!");
    setFormData({
      name: "",
      email: "",
    });
    setContactNumber("");
    setDesignation("");
    setCompany("");

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="main-container">
      <Header />
      <div className="privacy_box">
        <div className="privacy_h1">
          <ol className="breadcrumbs__list">
            <li className="breadcrumbs__li">
              <Link className="nav-link" to="/" onClick={toTop}>
                Home
              </Link>
            </li>
            <li className="breadcrumbs__li">
              <Link className="nav-link" to="/about" onClick={toTop}>
                About SIT
              </Link>
            </li>
            <li className="breadcrumbs__li">
              <Link className="nav-link nav-link--active" to="">
                Contact Us
              </Link>
            </li>
          </ol>
        </div>
        <div className="privacy_body">
          <h1>Contact Us</h1>
          <p>
            Find answers to frequently asked questions, or get in touch with us
            by sending us an enquiry. Alternatively, if you know who you are
            looking for, find their contact information in the relevant
            directories.
            <br />
            <br />
            <strong>Singapore Institute of Technology</strong>
            <br />1 Punggol Coast Road
            <br />
            Singapore 828608
            <br />
            Phone&nbsp;
            <a href="tel:+6565921189" style={{ color: "#da291c" }}>
              <strong>+65 6592 1189</strong>
            </a>
          </p>
          <h2>Get in Touch</h2>
          <p>
            Have questions, feedback, or need assistance? We're here to help!
            Get in touch with us through the contact form below, and our team
            will promptly address your inquiry.
          </p>
          <h5>Contact Information</h5>
          {/* name */}
          <div className="label_input">
            <label for="edit-name--2" className="input">
              <div className="label">
                <span
                  for="edit-name--2"
                  className="title__text js-form-required form-required"
                >
                  NAME
                </span>
              </div>
            </label>
            <input
              data-drupal-selector="edit-name"
              type="text"
              id="edit-name--2"
              name="name"
              value={formData.name}
              onChange={handleChange}
              size="60"
              maxlength="255"
              placeholder="Name"
              className={`form-text required input__field input ${
                errors.name ? "error-border" : ""
              }`}
              required="required"
              aria-required="true"
            />
          </div>
          {/* email */}
          <div className="label_input">
            <label for="edit-email--2" className="input">
              <div className="label">
                <span
                  for="edit-email--2"
                  className="title__text js-form-required form-required"
                >
                  EMAIL
                </span>
              </div>
            </label>
            <input
              data-drupal-selector="edit-email"
              type="email"
              id="edit-email--2"
              name="email"
              value={formData.email}
              onChange={handleChange}
              size="20"
              maxlength="254"
              placeholder="Email address"
              className={`form-email required input__field input ${
                errors.email ? "error-border" : ""
              }`}
              required="required"
              aria-required="true"
            />
          </div>
          {/* number */}
          <div className="label_input">
            <label for="edit-contact-number--2" className="input">
              <div className="label">
                <span for="edit-contact-number--2" className="title__text">
                  CONTACT NUMBER
                </span>
              </div>
            </label>
            <input
              data-drupal-selector="edit-contact-number"
              type="text"
              id="edit-contact-number--2"
              name="contact_number"
              value={contactNumber}
              onChange={(e) => setContactNumber(e.target.value)}
              size="60"
              maxlength="255"
              placeholder="Contact number"
              className="form-text input__field input"
            />
          </div>

          {/* designation */}
          <div className="label_input">
            <label for="edit-designation--2" className="input">
              <div className="label">
                <span for="edit-designation--2" className="title__text">
                  DESIGNATION
                </span>
              </div>
            </label>
            <input
              data-drupal-selector="edit-designation"
              type="text"
              id="edit-designation--2"
              name="designation"
              value={designation}
              onChange={(e) => setDesignation(e.target.value)}
              size="60"
              maxlength="255"
              placeholder="Designation"
              className="form-text input__field input"
            />
          </div>

          {/* company */}
          <div className="label_input">
            <label for="edit-company-organisation--2" className="input">
              <div className="label">
                <span
                  for="edit-company-organisation--2"
                  className="title__text"
                >
                  COMPANY/ORGANISATION
                </span>
              </div>
            </label>
            <input
              data-drupal-selector="edit-company-organisation"
              type="text"
              id="edit-company-organisation--2"
              name="company_organisation"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              size="60"
              maxlength="255"
              placeholder="Company/Organisation"
              className="form-text input__field input"
            />
          </div>

          {/* submit */}
          <div>
            <button
              className="contact_submit_button"
              type="submit"
              onClick={handleFormSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
