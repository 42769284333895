import React from "react";
import "../css/footer.css";
import { Link } from "react-router-dom";

function Footer() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="bottom-container">
        <div className="footer_inner">
          <div className="footer_inner_body">
            <div className="footer_body_contact">
              <ol className="contact-cards__list">
                <li className="contact-cards__li">
                  <div className="contact-card contact-card--dark">
                    <div className="contact-card__header">
                      <div className="label label--secondary">
                        <span className="title__text">Address </span>
                      </div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        width="24px"
                        height="24px"
                        aria-labelledby="title-pin"
                      >
                        <title id="title-pin">Location Pin</title>
                        <path d="M12 2C8.13 2 5 5.13 5 9c0 4.67 7 13 7 13s7-8.33 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S10.62 6.5 12 6.5s2.5 1.12 2.5 2.5S13.38 11.5 12 11.5z" />
                      </svg>
                    </div>
                    <Link className="contact-card__a" to="/">
                      1309 Coffeen Avenue STE 1200，Sheridan, Wyoming 82801
                    </Link>
                  </div>
                </li>
                <li className="contact-cards__li">
                  <div className="contact-card contact-card--dark">
                    <div className="contact-card__header">
                      <div className="label label--secondary">
                        <span className="title__text">Contact Us</span>
                      </div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        width="24px"
                        height="24px"
                        aria-labelledby="title-envelope"
                      >
                        <title id="title-envelope">Envelope</title>
                        <path d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z" />
                      </svg>
                    </div>
                    <Link
                      className="contact-card__a"
                      to="/contact"
                      onClick={toTop}
                    >
                      Send an enquiry
                    </Link>
                  </div>
                </li>
              </ol>
            </div>

            <div className="foot-social">
              <ul>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    width="24px"
                    height="24px"
                    aria-labelledby="title-facebook"
                  >
                    <title id="title-facebook">Facebook</title>
                    <path d="M22 12C22 6.48 17.52 2 12 2S2 6.48 2 12c0 5 3.66 9.13 8.44 9.88v-6.98h-2.54v-2.9h2.54v-2.22c0-2.5 1.5-3.88 3.8-3.88 1.1 0 2.26.2 2.26.2v2.5h-1.27c-1.26 0-1.65.78-1.65 1.57v1.83h2.78l-.44 2.9h-2.34v6.98C18.34 21.13 22 17 22 12z" />
                  </svg>
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    width="24px"
                    height="24px"
                    aria-labelledby="title-instagram"
                  >
                    <title id="title-instagram">Instagram</title>
                    <path d="M7 2C4.24 2 2 4.24 2 7v10c0 2.76 2.24 5 5 5h10c2.76 0 5-2.24 5-5V7c0-2.76-2.24-5-5-5H7zm0 2h10c1.65 0 3 1.35 3 3v10c0 1.65-1.35 3-3 3H7c-1.65 0-3-1.35-3-3V7c0-1.65 1.35-3 3-3zm8 3c-.55 0-1 .45-1 1s.45 1 1 1h2c.55 0 1-.45 1-1s-.45-1-1-1h-2zm-5 2c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 2c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2z" />
                  </svg>
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    width="24px"
                    height="24px"
                    aria-labelledby="title-linkedin"
                  >
                    <title id="title-linkedin">LinkedIn</title>
                    <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM8.66 18.34H6.34v-8h2.32v8zm-1.16-9.18c-.74 0-1.34-.6-1.34-1.34s.6-1.34 1.34-1.34c.74 0 1.34.6 1.34 1.34s-.6 1.34-1.34 1.34zm10.84 9.18h-2.32v-4.16c0-1.4-.48-2.36-1.68-2.36-.92 0-1.47.62-1.71 1.22-.09.21-.11.49-.11.77v4.53h-2.32s.03-7.36 0-8h2.32v1.14c.31-.48.86-1.16 2.1-1.16 1.53 0 2.68 1 2.68 3.16v4.86z" />
                  </svg>
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    width="24px"
                    height="24px"
                    aria-labelledby="title-youtube"
                  >
                    <title id="title-youtube">YouTube</title>
                    <path d="M19.8 8s-.2-1.4-.8-2c-.7-.7-1.4-.7-1.7-.8C15.2 5 12 5 12 5s-3.2 0-5.3.2c-.3 0-1 0-1.7.8-.6.6-.8 2-.8 2S4 9.6 4 11.2v1.6c0 1.6.2 3.2.2 3.2s.2 1.4.8 2c.7.7 1.7.7 2.2.8C8.8 19 12 19 12 19s3.2 0 5.3-.2c.3 0 1 0 1.7-.8.6-.6.8-2 .8-2s.2-1.6.2-3.2v-1.6c0-1.6-.2-3.2-.2-3.2zM10 14.5v-5l5 2.5-5 2.5z" />
                  </svg>
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    width="24px"
                    height="24px"
                    aria-labelledby="title-tiktok"
                  >
                    <title id="title-tiktok">TikTok</title>
                    <path d="M12 2c0-.55.45-1 1-1h2.6c.47 0 .87.34.97.8.34 1.56 1.52 2.71 3.1 2.96.5.08.93.49.93 1v3.28a1 1 0 0 1-.9 1c-3.04.35-4.84-1.07-5.7-2.1v6.7c0 2.12-1.72 3.85-3.84 3.85a3.84 3.84 0 1 1 2.68-6.57v-3.4A6.33 6.33 0 0 0 8.9 14a1.84 1.84 0 1 0 1.86 1.86v-11z" />
                  </svg>
                </li>
              </ul>
            </div>

            <div className="footer_block">
              <ul>
                <li>
                  <Link to="/privacy" onClick={toTop}>
                    Privacy Statement
                  </Link>
                </li>
                <li>
                  <Link to="/terms" onClick={toTop}>
                    Terms of Use
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer_block">
              <small>
                Copyright © 2024 Singapore Institute of Technology. All Rights
                Reserved.
              </small>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
