import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../css/header.css";

function Header() {
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    const scrollY = window.scrollY || document.documentElement.scrollTop;
    setScrolled(scrollY > 1);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="header-container">
      <div className="header">
        <div className={`header__inner ${scrolled ? "scrolled" : ""}`}>
          <div className="header_width_inner">
            <div className="width_top">
              <ul>
                <li>Punggol Campus</li>
                <li>Career Nexus 2025</li>
                <li>Careers</li>
                <li>Library</li>
                <li>Students</li>
                <li>Staff</li>
                <li>Alumni</li>
              </ul>
            </div>
            <div className="width_bottom">
              <div className="width_bottom_inner">
                <div className="width_bot_log1"></div>
                <div className="width_bot_log2"></div>
                <div className="width_bot_link">
                  <ul>
                    <li>
                      <Link to="/" onClick={toTop}>
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact" onClick={toTop}>
                        Contact
                      </Link>
                    </li>
                    <li>
                      <Link to="/privacy" onClick={toTop}>
                        Privacy
                      </Link>
                    </li>
                    <li>
                      <Link to="/about" onClick={toTop}>
                        About
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
