import React from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import "../css/privacy.css";

function Terms() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="main-container">
      <Header />
      <div className="privacy_box">
        <div className="privacy_h1">
          <ol className="breadcrumbs__list">
            <li className="breadcrumbs__li">
              <Link className="nav-link" to="/" onClick={toTop}>
                Home
              </Link>
            </li>
            <li className="breadcrumbs__li">
              <Link className="nav-link nav-link--active" to="">
                Terms of Use
              </Link>
            </li>
          </ol>
        </div>
        <div className="privacy_body">
          <h1>Terms of Use</h1>
          <h3>Agreement - Terms</h3>
          <ol class="start">
            <li>
              Thank you for visiting this Website (“The Website”). The Website
              is owned and maintained by the Singapore Institute of
              Technology&nbsp;(hereinafter “SIT”).
            </li>
            <li>
              All access of any of The Website is governed by the terms and
              conditions below (“Terms”). If you do not accept any of these
              Terms, exit immediately. Continue only if you accept these terms.
            </li>
            <li>
              In these Terms, the words “we”, “our” and “us” refer to SIT.
            </li>
            <li>
              SIT reserves the right to change these Terms from time to time.
              Your use of The Website constitutes your agreement to any modified
              Terms.
            </li>
          </ol>
          <h3>Access to The Website</h3>
          <ol>
            <li>
              The accessibility and operation of The Website relies on
              technologies outside our control. We do not guarantee continued
              accessibility or uninterrupted operation of The Website.
            </li>
          </ol>
          <h3>Relying on Information</h3>
          <ol>
            <li>
              The Website is provided for general information only. We do not
              guarantee the timeliness, accuracy, completeness or reliability of
              any statements, opinion, representation or other information in
              The Website. You should do your own checks.
            </li>
            <li>
              The materials located on The Website, including the information as
              well as software programmes available on or through it (“the
              Contents”), are protected by copyright, trademark and other forms
              of proprietary rights. All rights, title and interest in the
              Contents are owned by, licensed to and controlled by SIT.
            </li>
          </ol>
          <h3>Security</h3>
          <ol>
            <li>
              Where appropriate, we use available technology to protect the
              security of communications made through The Website. We do not,
              however, accept liability for the security, authenticity,
              integrity or confidentiality of any transactions and other
              communications made through The Website.
            </li>
            <li>
              Internet communications may be susceptible to interference or
              interception by third parties. Despite our best efforts, we make
              no warranties that The Website is free of infection by computer
              viruses or other unauthorised software.
            </li>
          </ol>
          <h3>Hyperlinks</h3>
          <ol>
            <li>
              We are not responsible or liable for the availability or content
              of any other internet site (not provided by us) linked to or from
              The Website. Access to any other site is at your own risk.
            </li>
            <li>
              If you create a link or frame to The Website you do so your own
              risk.
            </li>
          </ol>
          <h3>Intellectual Property</h3>
          <ol>
            <li>
              Materials, including design, source codes, pages, documents and
              online graphics, audio and video in The Website are protected by
              law. The Intellectual property rights in the materials are owned
              or licensed to us. All rights reserved (© Singapore Institute of
              Technology).
            </li>
            <li>
              Apart from any fair dealings for the purposes of private study,
              research, criticism or review, as permitted by law, no part of The
              Website may be reproduced or reused for any commercial purposes
              whatsoever without our prior written permission. The modification
              of the materials on The Website is prohibited.
            </li>
          </ol>
          <h3>Disclaimer of Warranties and Liability</h3>
          <p>We will not be liable for any loss or damage:</p>
          <ol>
            <li>
              that you may incur on account of using, visiting or relying on any
              statement, opinion, representation or information in The Website;
            </li>
            <li>
              resulting any delay in the operation or transmission,
              communications failure, Internet access difficulties malfunctions
              in equipment or software; or
            </li>
            <li>
              the conduct or views of any person who accesses or uses The
              Website.
            </li>
          </ol>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Terms;
